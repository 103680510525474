/* eslint-disable-next-line no-restricted-imports */
import styled, { css, keyframes } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const StyledContainer = styled.div`
	margin-top: 1.5rem;
	max-width: 51.6875rem;
	width: 100%;
	padding-bottom: 1.5rem;

	.traveler-media-carousel-container {
		transform: translateX(-0.75rem);
	}

	@media (max-width: 768px) {
		max-width: 21.375rem;
	}
`;

export const StyledHeadingWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	@media only screen and (min-width: 768px), print {
		.navigation-buttons-container {
			margin-right: 0.75rem;
			z-index: 1;
		}
	}
`;

export const StyledHeading = styled.div`
	${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)}
	margin-bottom: 1.5rem;
	color: ${colors.GREY_DS.G2};

	@media (max-width: 768px) {
		margin-bottom: 1rem;
	}
`;

const shimmer = keyframes`
  from {
    transform: rotate(15deg) translate(-90%, -50%)
  }
  to {
    transform: rotate(15deg) translate(10%, -50%)
  }
`;

const shimmerStyle = css`
	&:after {
		content: '';
		height: 260%;
		width: 210%;
		z-index: 1;
		position: absolute;
		border-radius: 6px;
		background: linear-gradient(
			90deg,
			rgba(226, 226, 226, 0) 0%,
			rgba(226, 226, 226, 0.6) 50.37%,
			rgba(226, 226, 226, 0) 100%
		);
		transform: rotate(15deg) translate(-50%, -50%);
		animation: ${shimmer} 1.5s ease-in-out infinite alternate;
	}
`;

export const StyledImage = styled.div<{
	$height: number;
	$width: number;
	$showShimmer?: boolean;
}>`
	border-radius: 6px;
	width: auto;
	height: ${({ $height }) => $height / 16}rem;
	width: ${({ $width }) => $width / 16}rem;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	margin: 0.125rem 0.75rem 0.125rem 0;

	${({ $showShimmer }) => $showShimmer && shimmerStyle}
	img {
		height: 100%;
		width: 100%;
		border-radius: 6px;
		opacity: ${({ $showShimmer }) => ($showShimmer ? 0 : 1)};
		transition: all 0.3s;
	}

	@media (max-width: 768px) {
		margin: 0;
		min-height: ${({ $height }) => $height / 16}rem;
		min-width: ${({ $width }) => $width / 16}rem;

		img {
			min-height: ${({ $height }) => $height / 16}rem;
			min-width: ${({ $width }) => $width / 16}rem;
		}
	}
`;

export const MobileCarousel = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	padding: 0 1.5rem;
	margin-left: -1.5rem;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100vw;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const ModalStoryContainer = styled.div`
	.modal-story-carousel {
		max-width: 49.5rem;
		margin: 1.25rem 0 2rem 0;
		width: 100%;
		position: relative;

		.arrow-button-list {
			transform: none;
			width: 100%;
		}

		.prev-button-wrapper,
		.next-button-wrapper {
			top: 21.875rem;
			right: -3.125rem;
			padding: 6.25rem;
		}

		.prev-button-wrapper {
			left: -3.125rem;
		}
	}
`;

export const ModalContainer = styled.div`
	.story-modal-container {
		background: ${colors.BLACK};
		z-index: 200001;
		padding: 0;
		margin: 0;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.core-modal-content {
		background: none;
		min-width: auto;
		min-height: 43.75rem;
		overflow: hidden;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
