import { notify } from 'Components/common/notify';
import { ITEM_BUFFER } from 'Components/common/travelerMediaTiles';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentLanguageCode } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	appendTravelerMediaByTgid,
	receiveTravelerMediaByTgid,
	requestTravelerMedia,
} from 'Actions/travelerMedia';

export const fetchTravelerMediaByTgid =
	({
		tgId,
		limit = ITEM_BUFFER,
		offset = 0,
	}: {
		tgId: string | number;
		limit?: number;
		offset?: number;
	}) =>
	(dispatch: (arg0: any) => void, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state);
		const url = `${getApiCDNBaseUrlV2({
			state,
		})}/api/v6/tour-groups/${tgId}/review-medias?language=${lang.toUpperCase()}&limit=${limit}&offset=${offset}`;

		dispatch(requestTravelerMedia({ tgId: tgId.toString() }));

		return fetch(url)
			.then(response => response.json())
			.then(json =>
				dispatch(
					(offset === 0
						? receiveTravelerMediaByTgid
						: appendTravelerMediaByTgid)({
						tgId: tgId.toString(),
						travelerMedia: json,
						url,
					}),
				),
			)
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
				dispatch(decrementAPICount());
			});
	};
