import { ActionTypes } from 'Actions/actions';

export const requestTravelerMedia = ({ tgId }: { tgId: string }) => ({
	payload: {
		tgId,
	},
	type: ActionTypes.REQUEST_TRAVELER_MEDIA,
});

export const receiveTravelerMediaByTgid = ({
	tgId,
	travelerMedia,
	url,
}: {
	tgId: string;
	url: string;
	travelerMedia: any;
}) => ({
	payload: {
		tgId,
		url,
		travelerMedia,
	},
	type: ActionTypes.RECEIVE_TRAVELER_MEDIA_BY_TGID,
});

export const appendTravelerMediaByTgid = ({
	tgId,
	travelerMedia,
	url,
}: {
	tgId: string;
	url: string;
	travelerMedia: any;
}) => ({
	payload: {
		tgId,
		url,
		travelerMedia,
	},
	type: ActionTypes.APPEND_TRAVELER_MEDIA_BY_TGID,
});
